<app-header4 [header4Type]="'training'"></app-header4>
<!-- Header2 end here-->

<!-- Top banner start here-->
<!-- <app-top-banner2 [topbanner2Type]="'finance'"></app-top-banner2> -->
<!-- Top banner end here-->



        <!-- Breadcrumb Section Start -->
        <div class="breadcrumb-wrap position-relative index-1 bg-title">
            <div class="br-bg br-bg-7 position-absolute top-0 end-0 md-none"
            data-aos="fade-in" data-aos-once="true" data-aos-duration="500"></div>
            <img src="./assets/images/onella/breadcrumb/br-shape-2.webp" alt="Image" class="br-shape-two position-absolute">
            <div class="container position-relative">
                <img src="./assets/images/onella/breadcrumb/br-shape-1.webp" alt="Image" class="br-shape-one position-absolute md-none">
                <img src="./assets/images/onella/breadcrumb/br-shape-3.webp" alt="Image" class="br-shape-three position-absolute md-none">
                <div class="row" >
                    <div class="col-xxl-6 col-xl-6 col-lg-6">
                        <ul class="br-menu list-unstyle d-inline-block" data-aos="fade-down" data-aos-once="true" data-aos-duration="500" data-aos-delay="500">
                            <li><a [routerLink]="['/home']">Home</a></li>
                            <li>About Us</li>
                        </ul>
                        <h2 class="br-title fw-medium text-white mb-0"
                        data-aos="fade-up" data-aos-once="true" data-aos-duration="500" data-aos-delay="500">Need Any Help? You can Contact With Us</h2>
                    </div>
                </div>
            </div>
        </div>
        <!-- Breadcrumb Section End -->

        <!-- Why Choose Us Start -->
        <div class="wh-area position-relative index-1 pt-100">
            <div class="container">
                <div class="row pb-100 align-items-center">
                    <div class="col-lg-6 pe-xxl-1">
                        <div class="wh-img-wrap position-relative">
                            <video width="100%" controls>
                                <source src="./assets/video/about-video.mp4" type="video/mp4">
                                Your browser does not support the video tag.
                              </video>
                            <!-- <img src="./assets/images/onella/logo/onella-soft.svg" alt="Image" data-aos="flip-left" data-aos-once="true" data-aos-duration="500"> -->
                            <!-- <img src="./assets/images/onella/about/wh-img-shape-1.webp" alt="Image" class="wh-shape-one position-absolute rotate"
                            data-aos="fade-up" data-aos-once="true" data-aos-duration="500" data-aos-delay="500">
                            <img src="./assets/images/onella/about/wh-img-shape-2.webp" alt="Image" class="wh-shape-two position-absolute bounce"
                            data-aos="fade-up" data-aos-once="true" data-aos-duration="500" data-aos-delay="500"> -->
                        </div>
                    </div>
                    <div class="col-lg-6 pe-xxl-0">
                        <div class="wh-content">
                            <div class="section-title">
                                <span class="section-subtitle d-inline-block fs-15 fw-semibold text-title"
                                data-aos="fade-up" data-aos-once="true" data-aos-duration="500">Why Choose Us</span>
                                <h2 class="mb-15" data-aos="fade-up" data-aos-once="true" data-aos-duration="500" data-aos-delay="500">Creative Digital Agency</h2>
                                <!-- <p>We are dedicated to guiding you a your financial journey with expertise, integrity, and a personalized approach. Our team of experienced for this financial consultants is committed.</p> -->
                                <p data-aos="fade-up" data-aos-once="true" data-aos-duration="500" data-aos-delay="1000">At Onella Soft , we offer 360 degree growth marketing solutions to help brands win long-term. Our data-driven approach brings you creative marketing solutions that work directly for your brand and your top line by leveraging end-to-end insights and real-time analytics across the consumer funnel.</p>
                            </div>
                            <h4 class="fs-24" data-aos="fade-up" data-aos-once="true" data-aos-duration="500" data-aos-delay="1500">We are award winning company</h4>
                            <div class="award-logo-wrap d-flex flex-wrap justify-content-between mb-0">
                                <img src="./assets/images/onella/about/award-1.webp" alt="Award" data-aos="fade-up" data-aos-once="true" data-aos-duration="500" data-aos-delay="2000">
                                <img src="./assets/images/onella/about/award-2.webp" alt="Award" data-aos="fade-up" data-aos-once="true" data-aos-duration="500" data-aos-delay="2400">
                                <img src="./assets/images/onella/about/award-3.webp" alt="Award" data-aos="fade-up" data-aos-once="true" data-aos-duration="500" data-aos-delay="2800">
                                <img src="./assets/images/onella/about/award-4.webp" alt="Award" data-aos="fade-up" data-aos-once="true" data-aos-duration="500" data-aos-delay="3000">
                            </div>
                            <!-- <a href="services.html" class="link style-four">View Our Services <img src="./assets/images/onella/icons/long-arrow-blue.svg" alt="Image"></a> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="background-color: #eef3ff">
                <div class="row justify-content-center pb-100 pt-100">
                    <div class="col-12 p-0">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-4 col-md-6">
                                    <div class="feature-card style-one d-flex flex-wrap mb-30 transition"
                                    data-aos="flip-left" data-aos-once="true" data-aos-duration="500">
                                        <div class="feature-icon bg-white position-relative d-flex flex-coulmn justify-content-center align-items-center transition">
                                            <img src="./assets/images/onella/icons/calculation.svg" alt="Image" class="transition">
                                        </div>
                                        <div class="feature-text">
                                            <h3 class="fs-20">Creative Solutions </h3>
                                            <p class="mb-0">Our team excels not just in maximizing your current resources but also in innovating and generating new assets to propel and expand your business</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-md-6 ps-xxl-4"
                                data-aos="flip-left" data-aos-once="true" data-aos-duration="500" data-aos-delay="500">
                                    <div class="feature-card style-one d-flex flex-wrap mb-30 transition">
                                        <div class="feature-icon bg-white position-relative d-flex flex-coulmn justify-content-center align-items-center transition">
                                            <img src="./assets/images/onella/icons/startup.svg" alt="Image" class="transition">
                                        </div>
                                        <div class="feature-text">
                                            <h3 class="fs-20">Dedicated & Shared Team</h3>
                                            <p class="mb-0">Your brand's expertise depends on project scope, using shared and dedicated resources tailored for optimal results and efficiency</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-md-6 ps-xxl-4"
                                data-aos="flip-left" data-aos-once="true" data-aos-duration="500" data-aos-delay="1000">
                                    <div class="feature-card style-one d-flex flex-wrap mb-30 transition">
                                        <div class="feature-icon bg-white position-relative d-flex flex-coulmn justify-content-center align-items-center transition">
                                            <img src="./assets/images/onella/icons/data-management.svg" alt="Image" class="transition">
                                        </div>
                                        <div class="feature-text">
                                            <h3 class="fs-20">Tech & Innovation</h3>
                                            <p class="mb-0">Unlock your brand's potential with tailored strategies, innovative techniques, new technologies, and a team dedicated to driving results</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Why Choose Us End -->

        <!-- Brand Section Start-->


        <app-testimonial3 [testimonialWrapper3Type]="'onella'"></app-testimonial3>


        <!-- <app-carousel-wrapper3 [carouselWrapper3Type]="'onella'"></app-carousel-wrapper3> -->
        <!-- <div class="ptb-130">
            <h6 class="subtitle f-optional text-center mb-50 fs-20 text-title">Join <b class="text_secondary">97,000+</b> companies who’ve reached</h6>
            <div class="brand-slider swiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="brand-logo">
                            <img src="./assets/vendor/bruk/img/brand/brand-1.webp" alt="Image" class="mx-auto d-block">
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="brand-logo">
                            <img src="./assets/vendor/bruk/img/brand/brand-2.webp" alt="Image" class="mx-auto d-block">
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="brand-logo">
                            <img src="./assets/vendor/bruk/img/brand/brand-3.webp" alt="Image" class="mx-auto d-block">
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="brand-logo">
                            <img src="./assets/vendor/bruk/img/brand/brand-4.webp" alt="Image" class="mx-auto d-block">
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="brand-logo">
                            <img src="./assets/vendor/bruk/img/brand/brand-5.webp" alt="Image" class="mx-auto d-block">
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="brand-logo">
                            <img src="./assets/vendor/bruk/img/brand/brand-6.webp" alt="Image" class="mx-auto d-block">
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="brand-logo">
                            <img src="./assets/vendor/bruk/img/brand/brand-7.webp" alt="Image" class="mx-auto d-block">
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="brand-logo">
                            <img src="./assets/vendor/bruk/img/brand/brand-8.webp" alt="Image" class="mx-auto d-block">
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="brand-logo">
                            <img src="./assets/vendor/bruk/img/brand/brand-1.webp" alt="Image" class="mx-auto d-block">
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- Brand Section End-->

        <!-- Project Section Start -->
        <!-- <div class="project-wrap">
            <div class="container">
                <div class="row align-items-center mb-40">
                    <div class="col-xl-5">
                        <div class="section-title">
                            <span class="section-subtitle d-inline-block fs-15 fw-semibold text-title">Success Stories</span>
                            <h2 class="mb-0">Explore Our Customer Success Stories</h2>
                        </div>
                    </div>
                    <div class="col-xl-3 offset-xl-4 text-md-end mt-sm-20">
                        <a href="projects.html" class="link style-one">View All Customer Result<img src="./assets/images/onella/icons/long-arrow-right.svg" alt="Image"></a>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="project-slider swiper">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <div class="project-card position-relative overflow-hidden round-2">
                                <img src="./assets/vendor/bruk/img/projects/project-1.webp" alt="Image" class="transition round-2">
                                <a href="projects.html" class="project-cat position-absolute fs-15">Consulting</a>
                                <div class="project-info d-flex flex-wrap align-items-center justify-content-between round-2">
                                    <span class="f-secondary">01</span>
                                    <h3 class="fs-20 mb-0"><a href="project-details.html">Best Mortgage Rates Guaranteed</a></h3>
                                    <a href="project-details.html" class="project-link d-flex flex-column align-items-center justify-content-center round-2"><img src="./assets/images/onella/icons/right-arrow-2.svg" alt="Image"></a>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="project-card position-relative overflow-hidden round-2">
                                <img src="./assets/vendor/bruk/img/projects/project-2.webp" alt="Image" class="transition round-2">
                                <a href="projects.html" class="project-cat position-absolute fs-15">Analysis</a>
                                <div class="project-info d-flex flex-wrap align-items-center justify-content-between round-2">
                                    <span class="f-secondary">02</span>
                                    <h3 class="fs-20 mb-0"><a href="project-details.html">Successful Digital for a Financial Institution</a></h3>
                                    <a href="project-details.html" class="project-link d-flex flex-column align-items-center justify-content-center round-2"><img src="./assets/images/onella/icons/right-arrow-2.svg" alt="Image"></a>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="project-card position-relative overflow-hidden round-2">
                                <img src="./assets/vendor/bruk/img/projects/project-3.webp" alt="Image" class="transition round-2">
                                <a href="projects.html" class="project-cat position-absolute fs-15">Business</a>
                                <div class="project-info d-flex flex-wrap align-items-center justify-content-between round-2">
                                    <span class="f-secondary">03</span>
                                    <h3 class="fs-20 mb-0"><a href="project-details.html">A Case Study on Market Expansion Submit</a></h3>
                                    <a href="project-details.html" class="project-link d-flex flex-column align-items-center justify-content-center round-2"><img src="./assets/images/onella/icons/right-arrow-2.svg" alt="Image"></a>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="project-card position-relative overflow-hidden round-2">
                                <img src="./assets/vendor/bruk/img/projects/project-4.webp" alt="Image" class="transition round-2">
                                <a href="projects.html" class="project-cat position-absolute fs-15">Business</a>
                                <div class="project-info d-flex flex-wrap align-items-center justify-content-between round-2">
                                    <span class="f-secondary">04</span>
                                    <h3 class="fs-20 mb-0"><a href="project-details.html">Digital Production Unique For A Company</a></h3>
                                    <a href="project-details.html" class="project-link d-flex flex-column align-items-center justify-content-center round-2"><img src="./assets/images/onella/icons/right-arrow-2.svg" alt="Image"></a>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="project-card position-relative overflow-hidden round-2">
                                <img src="./assets/vendor/bruk/img/projects/project-5.webp" alt="Image" class="transition round-2">
                                <a href="projects.html" class="project-cat position-absolute fs-15">Marketing</a>
                                <div class="project-info d-flex flex-wrap align-items-center justify-content-between round-2">
                                    <span class="f-secondary">05</span>
                                    <h3 class="fs-20 mb-0"><a href="project-details.html">Best Mortgage Rates Guaranteed</a></h3>
                                    <a href="project-details.html" class="project-link d-flex flex-column align-items-center justify-content-center round-2"><img src="./assets/images/onella/icons/right-arrow-2.svg" alt="Image"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slider-pagination project-pagination d-flex flex-wrap justify-content-center align-items-center"></div>
                </div>
            </div>
        </div> -->
        <!-- Project Section End -->
        
        <!-- Working Process Section Start -->
        <!-- <div class="work-process-wrap pt-130 pb-100 position-relative">
            <img src="./assets/vendor/bruk/img/footer-shape-2.webp" alt="Image" class="section-shape position-absolute end-0 sm-none">
            <div class="container px-xxl-0">
                <div class="section-title text-center mb-50">
                    <span class="section-subtitle d-inline-block fs-15 fw-semibold text-title">Work Process</span>
                    <h2 class="mb-15">How It Works</h2>
                </div>
                <div class="work-card-wrap d-flex flex-wrap justify-content-center">
                    <div class="work-card text-center position-relative index-1 mb-30">
                        <h3 class="fs-24">User Input And Authentication</h3>
                        <p>Users start by accessing the financial website through a web browser or a mobile application. They may need to create an account or log in using existing.</p>
                        <span class="work-counter text-white fs-20 d-flex flex-column align-items-center justify-content-center mx-auto">01</span>
                    </div>
                    <div class="work-card text-center position-relative index-1 mb-30">
                        <h3 class="fs-24">Data Retrieval And Analysis</h3>
                        <p>Users start by accessing the financial website through a web browser or a mobile application. They may need to create an account or log in using existing.</p>
                        <span class="work-counter text-white fs-20 d-flex flex-column align-items-center justify-content-center mx-auto">02</span>
                    </div>
                    <div class="work-card text-center position-relative index-1 mb-30">
                        <h3 class="fs-24">Transactions And Communication</h3>
                        <p>Users start by accessing the financial website through a web browser or a mobile application. They may need to create an account or log in using existing.</p>
                        <span class="work-counter text-white fs-20 d-flex flex-column align-items-center justify-content-center mx-auto">03</span>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- Working Process Section End -->

        <!-- Counter Section Start -->
        <!-- <div class="container position-relative">
            <img src="./assets/vendor/bruk/img/shape-5.webp" alt="Image" class="counter-shape-one position-absolute rotate">
            <div class="counter-card-wrap style-one d-flex flex-wrap pt-130 pb-100">
                <div class="counter-card position-relative text-center mb-30">
                    <h4>+<span class="counter">258</span>%</h4>
                    <p class="mb-0">Total increase in sales over only two months!</p>
                </div>
                <div class="counter-card position-relative text-center mb-30">
                    <h4>+<span class="counter">237</span>K</h4>
                    <p class="mb-0">A dedicated team of financial experts at your service.</p>
                </div>
                <div class="counter-card position-relative text-center mb-30">
                    <h4><span class="counter">8.2</span>X</h4>
                    <p class="mb-0">Rapid wealth financial for customer grow</p>
                </div>
                <div class="counter-card position-relative text-center mb-30">
                    <h4>$<span class="counter">10</span>+</h4>
                    <p class="mb-0">Cumulative trading volume for a customer sale</p>
                </div>
            </div>
        </div> -->
        <!-- Counter Section End -->

        <!-- Testimonial Section Start -->
        <!-- <div class="testimonial-wrap style-one bg-optional ptb-130">
            <div class="container position-relative">
                <img src="./assets/vendor/bruk/img/shape-3.webp" alt="Image" class="section-shape-one position-absolute rotate">
                <img src="./assets/vendor/bruk/img/shape-7.webp" alt="Image" class="section-shape-two position-absolute bounce">
                <div class="row">
                    <div class="col-xl-6 offset-xl-3 col-md-8 offset-md-2">
                        <div class="section-title text-center mb-50">
                            <span class="section-subtitle d-inline-block fs-15 fw-semibold text-title">Client Testimonials</span>
                            <h2 class="mb-0">What People Says About Our Financial Services</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="testimonial-slider-one swiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="testimonial-card style-one">
                            <div class="client-img-wrap d-flex justify-content-between">
                                <div class="client-img rounded-circle">
                                    <img src="./assets/vendor/bruk/img/testimonials/client-1.webp" alt="Image" class="rounded-circle">
                                </div>
                                <img src="./assets/vendor/bruk/img/testimonials/star.svg" alt="Image" class="star-icon">
                            </div>
                            <div class="client-quote bg-white ms-auto round-2">
                                <p>“Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore consectetur. Amet sed dolor for this net site.”</p>
                                <div class="client-info-wrap d-flex flex-wrap align-items-center justify-content-between">
                                    <div class="client-info">
                                        <h5 class="fs-20">Jenifer Brown</h5>
                                        <span>Product Designer</span>
                                    </div>
                                    <img src="./assets/images/onella/icons/square-quote.svg" alt="Image" class="quote-icon">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="testimonial-card style-one">
                            <div class="client-img-wrap d-flex justify-content-between">
                                <div class="client-img rounded-circle">
                                    <img src="./assets/vendor/bruk/img/testimonials/client-2.webp" alt="Image" class="rounded-circle">
                                </div>
                                <img src="./assets/vendor/bruk/img/testimonials/star.svg" alt="Image" class="star-icon">
                            </div>
                            <div class="client-quote bg-white ms-auto round-2">
                                <p>“Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore consectetur. Amet sed dolor for this net site.”</p>
                                <div class="client-info-wrap d-flex flex-wrap align-items-center justify-content-between">
                                    <div class="client-info">
                                        <h5 class="fs-20">Robert Taylor</h5>
                                        <span>Manager at UIC</span>
                                    </div>
                                    <img src="./assets/images/onella/icons/square-quote.svg" alt="Image" class="quote-icon">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="testimonial-card style-one">
                            <div class="client-img-wrap d-flex justify-content-between">
                                <div class="client-img rounded-circle">
                                    <img src="./assets/vendor/bruk/img/testimonials/client-3.webp" alt="Image" class="rounded-circle">
                                </div>
                                <img src="./assets/vendor/bruk/img/testimonials/star.svg" alt="Image" class="star-icon">
                            </div>
                            <div class="client-quote bg-white ms-auto round-2">
                                <p>“Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore consectetur. Amet sed dolor for this net site.”</p>
                                <div class="client-info-wrap d-flex flex-wrap align-items-center justify-content-between">
                                    <div class="client-info">
                                        <h5 class="fs-20">Jhon Smith</h5>
                                        <span>Web Developer</span>
                                    </div>
                                    <img src="./assets/images/onella/icons/square-quote.svg" alt="Image" class="quote-icon">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="testimonial-card style-one">
                            <div class="client-img-wrap d-flex justify-content-between">
                                <div class="client-img rounded-circle">
                                    <img src="./assets/vendor/bruk/img/testimonials/client-4.webp" alt="Image" class="rounded-circle">
                                </div>
                                <img src="./assets/vendor/bruk/img/testimonials/star.svg" alt="Image" class="star-icon">
                            </div>
                            <div class="client-quote bg-white ms-auto round-2">
                                <p>“Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore consectetur. Amet sed dolor for this net site.”</p>
                                <div class="client-info-wrap d-flex flex-wrap align-items-center justify-content-between">
                                    <div class="client-info">
                                        <h5 class="fs-20">Jenifer Brown</h5>
                                        <span>Product Designer</span>
                                    </div>
                                    <img src="./assets/images/onella/icons/square-quote.svg" alt="Image" class="quote-icon">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="testimonial-card style-one">
                            <div class="client-img-wrap d-flex justify-content-between">
                                <div class="client-img rounded-circle">
                                    <img src="./assets/vendor/bruk/img/testimonials/client-1.webp" alt="Image" class="rounded-circle">
                                </div>
                                <img src="./assets/vendor/bruk/img/testimonials/star.svg" alt="Image" class="star-icon">
                            </div>
                            <div class="client-quote bg-white ms-auto round-2">
                                <p>“Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore consectetur. Amet sed dolor for this net site.”</p>
                                <div class="client-info-wrap d-flex flex-wrap align-items-center justify-content-between">
                                    <div class="client-info">
                                        <h5 class="fs-20">Jenifer Brown</h5>
                                        <span>Product Designer</span>
                                    </div>
                                    <img src="./assets/images/onella/icons/square-quote.svg" alt="Image" class="quote-icon">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="slider-pagination testimonial-pagination d-flex align-items-center justify-content-center"></div>
            </div>
        </div> -->
        <!-- Testimonial Section End -->





        <app-footer4></app-footer4>

<!-- Go-top1 section start here -->
<app-go-top1></app-go-top1>
<!-- Go-top1 section end here -->
<app-header4 [header4Type]="'training'"></app-header4>



        <!-- Breadcrumb Section Start -->
        <div class="breadcrumb-wrap position-relative index-1 bg-title">
            <div class="br-bg br-bg-2 position-absolute top-0 end-0 md-none"
            data-aos="fade-in" data-aos-once="true" data-aos-duration="500"></div>
            <img src="./assets/images/onella/breadcrumb/br-shape-2.webp" alt="Image" class="br-shape-two position-absolute">
            <div class="container position-relative">
                <img src="./assets/images/onella/breadcrumb/br-shape-1.webp" alt="Image" class="br-shape-one position-absolute md-none">
                <img src="./assets/images/onella/breadcrumb/br-shape-3.webp" alt="Image" class="br-shape-three position-absolute md-none">
                <div class="row">
                    <div class="col-xxl-6 col-xl-6 col-lg-6">
                        <ul class="br-menu list-unstyle d-inline-block" data-aos="fade-down" data-aos-once="true" data-aos-duration="500" data-aos-delay="500">
                            <li><a [routerLink]="['/home']">Home</a></li>
                            <li>Service</li>
                        </ul>
                        <h2 class="br-title fw-medium text-white mb-0" data-aos="fade-up" data-aos-once="true" data-aos-duration="500" data-aos-delay="500">What Are You Looking For?: Leads, Website Traffic, Promotion, Branding & Sales</h2>
                    </div>
                </div>
            </div>
        </div>
        <!-- Breadcrumb Section End -->

        <!-- Service Section Start -->
        <!-- <div class="container ptb-130">
            <div class="row justify-content-center">
                <div class="col-xxl-3 col-xl-4 col-md-6">
                    <div class="service-card bg-optional text-center transition mb-65">
                        <img src="../assets/images/onella/icons/investment.svg" alt="Image" class="service-icon transition">
                        <h3 class="fs-24"><a href="#" class="transition">Investment</a></h3>
                        <p class="transition">Ever Find Yourself Staring At  Computer is Screen A Good that Consulting that</p>
                        <a href="#" class="service-link d-flex flex-column align-items-center justify-content-center round-2 transition"><img src="../assets/images/onella/icons/right-arrow-2.svg" alt="Image"></a>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-md-6">
                    <div class="service-card bg-optional text-center transition mb-65">
                        <img src="../assets/images/onella/icons/retirement.svg" alt="Image" class="service-icon transition">
                        <h3 class="fs-24"><a href="#" class="transition">Retirement</a></h3>
                        <p class="transition">Ever Find Yourself Staring At  Computer is Screen A Good that Consulting that</p>
                        <a href="#" class="service-link d-flex flex-column align-items-center justify-content-center round-2 transition"><img src="../assets/images/onella/icons/right-arrow-2.svg" alt="Image"></a>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-md-6">
                    <div class="service-card bg-optional text-center transition mb-65">
                        <img src="../assets/images/onella/icons/education.svg" alt="Image" class="service-icon transition">
                        <h3 class="fs-24"><a href="#" class="transition">Education</a></h3>
                        <p class="transition">Ever Find Yourself Staring At  Computer is Screen A Good that Consulting that</p>
                        <a href="#" class="service-link d-flex flex-column align-items-center justify-content-center round-2 transition"><img src="../assets/images/onella/icons/right-arrow-2.svg" alt="Image"></a>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-md-6">
                    <div class="service-card bg-optional text-center transition mb-65">
                        <img src="../assets/images/onella/icons/investment.svg" alt="Image" class="service-icon transition">
                        <h3 class="fs-24"><a href="#" class="transition">Management</a></h3>
                        <p class="transition">Ever Find Yourself Staring At  Computer is Screen A Good that Consulting that</p>
                        <a href="#" class="service-link d-flex flex-column align-items-center justify-content-center round-2 transition"><img src="../assets/images/onella/icons/right-arrow-2.svg" alt="Image"></a>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-md-6">
                    <div class="service-card bg-optional text-center transition mb-65">
                        <img src="../assets/images/onella/icons/retirement.svg" alt="Image" class="service-icon transition">
                        <h3 class="fs-24"><a href="#" class="transition">Wealth Collection</a></h3>
                        <p class="transition">Ever Find Yourself Staring At  Computer is Screen A Good that Consulting that</p>
                        <a href="#" class="service-link d-flex flex-column align-items-center justify-content-center round-2 transition"><img src="../assets/images/onella/icons/right-arrow-2.svg" alt="Image"></a>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-md-6">
                    <div class="service-card bg-optional text-center transition mb-65">
                        <img src="../assets/images/onella/icons/education.svg" alt="Image" class="service-icon transition">
                        <h3 class="fs-24"><a href="#" class="transition">Risk Management</a></h3>
                        <p class="transition">Ever Find Yourself Staring At  Computer is Screen A Good that Consulting that</p>
                        <a href="#" class="service-link d-flex flex-column align-items-center justify-content-center round-2 transition"><img src="../assets/images/onella/icons/right-arrow-2.svg" alt="Image"></a>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-md-6">
                    <div class="service-card bg-optional text-center transition mb-65">
                        <img src="../assets/images/onella/icons/investment.svg" alt="Image" class="service-icon transition">
                        <h3 class="fs-24"><a href="#" class="transition">Tax Planning</a></h3>
                        <p class="transition">Ever Find Yourself Staring At  Computer is Screen A Good that Consulting that</p>
                        <a href="#" class="service-link d-flex flex-column align-items-center justify-content-center round-2 transition"><img src="../assets/images/onella/icons/right-arrow-2.svg" alt="Image"></a>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-md-6">
                    <div class="service-card bg-optional text-center transition mb-65">
                        <img src="../assets/images/onella/icons/retirement.svg" alt="Image" class="service-icon transition">
                        <h3 class="fs-24"><a href="#" class="transition">Estate Planning</a></h3>
                        <p class="transition">Ever Find Yourself Staring At  Computer is Screen A Good that Consulting that</p>
                        <a href="#" class="service-link d-flex flex-column align-items-center justify-content-center round-2 transition"><img src="../assets/images/onella/icons/right-arrow-2.svg" alt="Image"></a>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- Service Section End -->


        <!-- Service Section Start -->
        <div class="service-wrap style-one position-relative index-1" data-cue="slideInUp">
            <img src="./assets/images/onella/icons/footer-shape-1.webp" alt="Image" class="section-shape-one position-absolute">
            
            <div class="service-slider swiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="service-card-item position-relative index-1 ptb-130">
                            <div class="container">
                                <!-- <div class="section-title text-center mb-50">
                                    <span class="section-subtitle d-inline-block fs-15 fw-semibold text-title mb-40">Our Services</span>
                                    <h2 class="mb-15">Digital Agency</h2>
                                    <span class="service-counter mt-sm-20">01</span>
                                </div> -->
                                <div class="row align-items-center">
                                    <div class="col-lg-3">
                                        <div class="service-bg service-bg-1" data-aos="zoom-in" data-aos-once="true" data-aos-duration="500"></div>
                                    </div>
                                    <div class="col-lg-9 ps-xxl-3">
                                        <div class="service-card-wrap d-flex flex-wrap">
                                            <div class="service-card bg-white text-center transition" 
                                            data-aos="flip-left" data-aos-once="true" data-aos-delay="1500" data-aos-duration="500">
                                                <img src="./assets/images/onella/icons/investment.svg" alt="Image" class="service-icon transition">
                                                <h3 class="fs-24"><a href="#" class="transition">Digital Marketing</a></h3>
                                                <p class="transition mb-0">Global reach, personalized efforts, real-time results, powerful tool</p>
                                                <!-- <a href="#" class="service-link d-flex flex-column align-items-center justify-content-center round-2 transition"><img src="./assets/images/onella/icons/right-arrow-2.svg" alt="Image"></a> -->
                                            </div>
                                            <div class="service-card bg-white text-center transition" 
                                            data-aos="flip-left" data-aos-once="true" data-aos-delay="2000" data-aos-duration="500">
                                                <img src="./assets/images/onella/icons/retirement.svg" alt="Image" class="service-icon transition">
                                                <h3 class="fs-24"><a href="#" class="transition">Software Development</a></h3>
                                                <p class="transition mb-0">Creating, maintaining websites with a focus on UI and UX</p>
                                                <!-- <a href="#" class="service-link d-flex flex-column align-items-center justify-content-center round-2 transition"><img src="./assets/images/onella/icons/right-arrow-2.svg" alt="Image"></a> -->
                                            </div>
                                            <div class="service-card bg-white text-center transition"
                                            data-aos="flip-left" data-aos-once="true" data-aos-delay="2500" data-aos-duration="500">
                                                <img src="./assets/images/onella/icons/education.svg" alt="Image" class="service-icon transition">
                                                <h3 class="fs-24"><a href="#" class="transition">Design Studio </a></h3>
                                                <p class="transition mb-0">Balance user-centric design for stunning solutions. Get expert UX audits</p>
                                                <!-- <a href="#" class="service-link d-flex flex-column align-items-center justify-content-center round-2 transition"><img src="./assets/images/onella/icons/right-arrow-2.svg" alt="Image"></a> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="slider-pagination service-pagination d-flex flex-lg-column align-items-center justify-content-center"></div>
            </div>
        </div>
        <!-- Service Section End -->

        <!-- Working Process Section Start -->
        <div class="work-process-wrap pt-130 pb-100 position-relative" style="background-color: #eff4fe;">
            <!-- <img src="./assets/vendor/bruk/img/footer-shape-2.webp" alt="Image" class="section-shape position-absolute end-0 sm-none bounce"> -->
            <div class="container px-xxl-0">
                <div class="section-title text-center mb-50">
                    <span class="section-subtitle d-inline-block fs-15 fw-semibold text-title"
                    data-aos="fade-up" data-aos-once="true" data-aos-duration="500">Work Process</span>
                    <h2 class="mb-15"
                    data-aos="fade-up" data-aos-once="true" data-aos-duration="500" data-aos-delay="500">How It Works</h2>
                </div>
                <div class="work-card-wrap d-flex flex-wrap justify-content-center">
                    <div class="work-card text-center position-relative index-1 mb-30" 
                    data-aos="fade-up" data-aos-once="true" data-aos-duration="500" data-aos-delay="1000">
                        <h3 class="fs-24">Research & Strategy</h3>
                        <p>We employ a dual methodology in our research. Firstly, we assess our clients' performance relative to their competitors by analyzing consumer interactions. Next, we merge this data with the brand's initiatives to gauge their effectiveness. This approach enables us to deliver industry-leading digital marketing services.</p>
                        <span class="work-counter text-white fs-20 d-flex flex-column align-items-center justify-content-center mx-auto">01</span>
                    </div>
                    <div class="work-card text-center position-relative index-1 mb-30"
                    data-aos="fade-up" data-aos-once="true" data-aos-duration="500" data-aos-delay="1500">
                        <h3 class="fs-24">Idea Generation</h3>
                        <p>Idea generation in digital marketing involves the creative process of brainstorming and conceptualizing innovative strategies and content to engage target audiences effectively online</p>
                        <span class="work-counter text-white fs-20 d-flex flex-column align-items-center justify-content-center mx-auto">02</span>
                    </div>
                    <div class="work-card text-center position-relative index-1 mb-30"
                    data-aos="fade-up" data-aos-once="true" data-aos-duration="500" data-aos-delay="2000">
                        <h3 class="fs-24">Work Optimization</h3>
                        <p>As an advertising and digital marketing agency, Work Optimisation is at the heart of Onella Soft. To stay ahead of the game, we constantly strive to deliver Highly Optimised Work that is always available for our clients worldwide.</p>
                        <span class="work-counter text-white fs-20 d-flex flex-column align-items-center justify-content-center mx-auto">03</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- Working Process Section End -->

        <!-- Footer Start -->
        <app-footer4></app-footer4>
        <!-- Footer End -->



<!-- Go-top1 section start here -->
<app-go-top1></app-go-top1>
<!-- Go-top1 section end here -->
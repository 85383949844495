<!-- image-text-wrapper3 section start here -->
<section class="container-fluid section testimonial-wrapper-3" *ngIf="testimonialWrapper3Type=='onella'">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center heading-wrapper mb-3">
        <div class="w-75 m-auto">
          <h4 class="h2 mb-3" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="500">
            Testimonials by<span class="chatbot-text-primary"> Clients</span>
          </h4>
          <p class="text-muted" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="500" data-aos-delay="500">
            We will be happy to organize a 30 minute demo to showcase why the
            industry leaders prefer all in one for Conversational AI platform.
          </p>
        </div>
      </div>
    </div>
    <div class="row" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="500" data-aos-delay="1000">
      <div class="col-12">
        <owl-carousel-o [options]="testimonial1">
          <ng-template carouselSlide>
            <div class="testimonial-item">
              <div class="testimonial-content float-start w-100">
                <div class="top-bladge float-start">
                  <span
                    class="
                      bold-text
                      float-start
                      w-100
                      text-center text-white
                      fw-bold
                    "
                    >80%</span
                  >
                  <span
                    class="
                      support-text
                      float-start
                      w-100
                      text-center text-white
                      mt-2
                    "
                    >Open Rate</span
                  >
                </div>
                <!-- <div class="profile-img float-start">
                  <img src="./assets/images/onella/logo/samarthview.webp" />
                </div> -->
                <div class="testominial-text float-end text-center">
                  <p>
                    I had a great experience working on my project with Onellia Soft . The quality of work done by them was exceptional. The management was very effective throughout the project, making the results quite impressive.
                  </p>
                  <div class="testi-meta mt-2 float-start w-100">
                    <span class="comapny-logo float-start w-100 mt-3 text-center">
                      <img src="./assets/images/onella/logo/samarthview.webp" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="testimonial-item">
              <div class="testimonial-content float-start w-100">
                <div class="top-bladge float-start">
                  <span
                    class="
                      bold-text
                      float-start
                      w-100
                      text-center text-white
                      fw-bold
                    "
                    >100%</span
                  >
                  <span
                    class="
                      support-text
                      float-start
                      w-100
                      text-center text-white
                      mt-2
                    "
                    >Chat Support</span
                  >
                </div>
                <!-- <div class="profile-img float-start">
                  <img src="./assets/images/users/2.png" />
                </div> -->
                <div class="testominial-text float-end">
                  <p>
                    I had the pleasure of working with Onella Soft for our recent digital marketing campaign, and the results have been nothing short of exceptional. From the very beginning, their team demonstrated a deep understanding of our business goals and crafted a comprehensive strategy that perfectly aligned with our needs.
                  </p>
                  <div class="testi-meta mt-2 float-start w-100">
                    <span class="comapny-logo float-start w-100 mt-3 text-center">
                      <img src="./assets/images/onella/logo/flash-broadband.jpeg" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="testimonial-item">
              <div class="testimonial-content float-start w-100">
                <div class="top-bladge float-start">
                  <span
                    class="
                      bold-text
                      float-start
                      w-100
                      text-center text-white
                      fw-bold
                    "
                    >96%</span
                  >
                  <span
                    class="
                      support-text
                      float-start
                      w-100
                      text-center text-white
                      mt-2
                    "
                    >Bot Deflection</span
                  >
                </div>
                <!-- <div class="profile-img float-start">
                  <img src="./assets/images/users/3.png" />
                </div> -->
                <div class="testominial-text float-end">
                  <p>
                    I recently had the pleasure of working with Onella Soft for our website development project, and I couldn't be more satisfied with the outcome. From the initial consultation to the final launch, their team demonstrated exceptional professionalism, expertise, and dedication.
                  </p>
                  <div class="testi-meta mt-2 float-start w-100">
                    <span class="comapny-logo float-start w-100 mt-3 text-center">
                      <img src="./assets/images/onella/logo/Khubba-Logo.png" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- image-text-wrapper3 section end here -->

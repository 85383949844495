
<app-header4 [header4Type]="'training'"></app-header4>


<div class="custom-toast hide" [ngClass]="successToast ? 'show' : 'hide' ">
    <p class="font-16">We got your enquiry. Our executive we be get in touch with you soon. <br/>Thank you..!!</p>
</div>
        
        <!-- Breadcrumb Section Start -->
        <div class="breadcrumb-wrap position-relative index-1 bg-title">
            <div class="br-bg br-bg-4 position-absolute top-0 end-0 md-none"
            data-aos="fade-in" data-aos-once="true" data-aos-duration="500"></div>
            <img src="./assets/images/onella/breadcrumb/br-shape-2.webp" alt="Image" class="br-shape-two position-absolute">
            <div class="container position-relative">
                <img src="./assets/images/onella/breadcrumb/br-shape-1.webp" alt="Image" class="br-shape-one position-absolute md-none">
                <img src="./assets/images/onella/breadcrumb/br-shape-3.webp" alt="Image" class="br-shape-three position-absolute md-none">
                <div class="row">
                    <div class="col-xxl-6 col-xl-6 col-lg-6">
                        <ul class="br-menu list-unstyle d-inline-block" data-aos="fade-down" data-aos-once="true" data-aos-duration="500" data-aos-delay="500">
                            <li><a href="#">Home</a></li>
                            <li>Contact Us</li>
                        </ul>
                        <h2 class="br-title fw-medium text-white mb-0" data-aos="fade-up" data-aos-once="true" data-aos-duration="500" data-aos-delay="500">Need Any Help? You can Contact With Us</h2>
                    </div>
                </div>
            </div>
        </div>
        <!-- Breadcrumb Section End -->

        <!-- Contact Section Start -->
        <div class="contact-wrap style-one position-relative ptb-130 overflow-hidden">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="contact-img-wrap position-relative me-xxl-auto mb-md-30">
                            <div class="contact-img position-relative"
                            data-aos="flip-left" data-aos-once="true" data-aos-duration="500">
                                <img src="./assets/images/onella/contact/contact-img-1.webp" alt="Image">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="contact-content mb-0">
                            <div class="section-title mb-30">
                                <span class="section-subtitle d-inline-block fs-15 fw-semibold text-title"
                                data-aos="fade-up" data-aos-once="true" data-aos-duration="500">Contact With Us</span>
                                <h2 class="mb-0"
                                data-aos="fade-up" data-aos-once="true" data-aos-duration="500" data-aos-delay="500">Contact With Us</h2>
                            </div>
                            <form id="enquiryForm" class="contact-form"
                            data-aos="fade-up" data-aos-once="true" data-aos-duration="500" data-aos-delay="1000">
                                <div class="form-group d-flex flex-wrap align-items-center justify-content-between">
                                    <label for="name">Full Name</label>
                                    <input type="text" name="full_name" id="name" class="fs-15 h-52 bg-transparent" placeholder="Enter Full Name" required>
                                </div>
                                <div class="form-group d-flex flex-wrap align-items-center justify-content-between">
                                    <label for="phone_no">Phone</label>
                                    <input type="number" name="phone_no" id="phone_no" class="fs-15 h-52 bg-transparent" placeholder="Enter Phone Number" required>
                                </div>
                                <div class="form-group d-flex flex-wrap align-items-start justify-content-between">
                                    <label for="msg" class="pt-xxl-2">Message</label>
                                    <textarea name="msg" id="msg" cols="30" rows="10" class="bg-transparent resize-0" placeholder="Type a message" required></textarea>
                                </div>

                                <div class="form-group d-flex flex-wrap align-items-center">
                                    <label for="msg"></label>
                                    <button class="btn style-two" type="submit">Send Message</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Contact Section End -->


        <!-- Footer Start -->
        <app-footer4></app-footer4>
        <!-- Footer End -->


<!-- Go-top1 section start here -->
<app-go-top1></app-go-top1>
<!-- Go-top1 section end here -->




<!-- <div class="modal" id="enquirySuccessModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
              <p class="font-16">Your enquiry is sent successfuly. Our executive we be get in touch with you soon. Thank you..!!</p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
</div> -->
<div class="modal fade" id="enquirySuccessModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <p class="font-16">Your enquiry is sent successfuly. Our executive we be get in touch with you soon. Thank you..!!</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
<div class="container-fluid" 
data-aos="fade-up" data-aos-once="true" data-aos-duration="500">
    <div class="row footer-4">
        <div class="col-12 p-0">
            <p class="copyright-text text-center text-offwhite mb-0 float-start w-100 font-14">
                <i class="ri-copyright-line"></i>
                <span class="text-white fw-semibold"> Onella Soft</span> is proudly developed by Onella Soft
            </p>
        </div>
    </div>
</div>


import { Component, OnInit } from '@angular/core';

declare var $ : any;
@Component({
  selector: 'app-nmnp-contact',
  templateUrl: './nmnp-contact.component.html',
  styleUrls: ['./nmnp-contact.component.scss']
})
export class NmnpContactComponent implements OnInit {

  constructor() { }
  
  successToast : boolean = false;

  ngOnInit(): void {


    $('#enquiryForm').on('submit', function(event) {
      event.preventDefault(); // prevent reload
      
      var formData = new FormData(this);
      formData.append('service_id', 'service_83xfl6i');
      formData.append('template_id', 'enquiryForm');
      formData.append('user_id', 'eeBpZJ0UIQKutKScV');
      // formData.append('user_id', 'user_BInQbvjRZ49oreGR3ddU3');

      $.ajax('https://api.emailjs.com/api/v1.0/email/send-form', {
          type: 'POST',
          data: formData,
          contentType: false, // auto-detection
          processData: false // no need to parse formData to string
      }).done(function() {
          // $("#enquiryModal").modal('hide');
          // $("#enquirySuccessModal").modal('show');
          this.successToast = true
          setTimeout(()=>{
            this.successToast = false
        }, 10000);
      }).fail(function(error) {
          alert('Oops... ' + JSON.stringify(error));
      });
    });
  }

}

<div class="top-banner2" *ngIf="topbanner2Type=='botStationAi'" data-aos="zoom-in-up">
    <div class="container content-area">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="w-75 m-auto">
                    <h2 class="h2 text-white mb-4" data-aos="fade-right" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="300">Scale your business with service Bot Station AI</h2>
                    <p class="text-white-50 mb-4"  data-aos="fade-right" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="400">Create your chatbot with ease by moving customizable elements over 
                        the scenario. Drag and drop responses and actions to compose the 
                        best conversational experience.</p>
                    <a href="#" class="btn btn-primary mt-1" data-aos="fade-right" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="500">Read More</a>
                </div>
            </div>
            <!-- <div class="col-12 col-md-6 text-center">
                <img src="./assets/images/bot-station-ai/bot/full-bot-1.png" class="w-100%" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="600"/>
            </div> -->
        </div>
    </div>
</div>

<div class="top-banner2" *ngIf="topbanner2Type=='finance'">
    <div class="container content-area">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="hero-content">
                    <span class="hero-subtitle d-inline-block fs-15 fw-semibold">Your Future Financial Planning</span>
                    <h1 class="text-white hero-title">Your Financial <img src="./assets/images/onella/icons/up-right-arrow.svg" alt="Image"> Journey</h1>
                    <p class="hero-para">Celebrating <span class="text_secondary">24+</span> years of taking care of our clients, each other, and our Financial community.</p>
                    <a href="#" class="btn style-one hero-btn">Schedule A Free Consultation</a>
                </div>
            </div>
            <!-- <div class="col-12 col-md-6 text-center">
                <img src="./assets/images/bot-station-ai/bot/full-bot-1.png" class="w-100%" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000" data-aos-delay="600"/>
            </div> -->
        </div>
    </div>
</div>

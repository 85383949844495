<!-- <div class="loader">
    <div class="container">

        <svg version="1.1" id="hei-loader" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="-16 5.5 115.3 141.5" style="enable-background:new -16 5.5 115.3 141.5;" xml:space="preserve">
      
          <g id="bulb_1_">
            <path id="bulb-body-fill" class="st0" d="M79.7,67.8c0-18.4-16.9-33.2-37.7-33.2S4.3,49.4,4.3,67.8c0,7.3,2.7,14,7.1,19.4
              c0.5,0.7,15.5,21.9,16.7,30.8c1.3,9.1,1.3,11.5,1.3,11.5h25.2c0,0,0-2.4,1.3-11.5c1.3-8.9,16.2-30,16.7-30.8
              C77.1,81.8,79.7,75.1,79.7,67.8" />
            <path id="bulb-body" class="st1" d="M79.4,67.8c0-18.4-16.9-33.2-37.7-33.2S4,49.4,4,67.8c0,7.3,2.7,14,7.1,19.4
              c0.5,0.7,15.5,21.9,16.7,30.8c1.3,9.1,1.3,11.5,1.3,11.5h25.2c0,0,0-2.4,1.3-11.5c1.3-8.9,16.2-30,16.7-30.8
              C76.8,81.8,79.4,75.1,79.4,67.8" />
            <g>
              <line id="one" class="st2" x1="-15.3" y1="36.8" x2="-4.8" y2="47.4" />
              <line id="two" class="st2" x1="-3.4" y1="16.6" x2="8.1" y2="32.7" />
              <line id="three" class="st2" x1="21" y1="13.2" x2="24.5" y2="26.8" />
              <line id="four" class="st2" x1="41.4" y1="5.5" x2="42.3" y2="24.9" />
              <line id="five" class="st2" x1="61.9" y1="14.2" x2="57.8" y2="27.9" />
              <line id="six" class="st2" x1="88.2" y1="19.4" x2="74.5" y2="34" />
              <line id="seven" class="st2" x1="98.7" y1="40" x2="86.2" y2="48.8" />
            </g>
            <line id="middle-screw" class="st1" x1="25.9" y1="138.5" x2="58" y2="138.5" />
            <line id="bottom-screw" class="st1" x1="25.9" y1="146" x2="58" y2="146" />
          </g>
        </svg>
      
      </div>
</div> -->

<app-header4 [header4Type]="'training'"></app-header4>
<!-- Header2 end here-->

<!-- Top banner start here-->
<!-- <app-top-banner2 [topbanner2Type]="'finance'"></app-top-banner2> -->
<!-- Top banner end here-->




        <!-- Hero Section Start -->
        <div class="hero-section style-one position-relative">
            <div class="hero-slider swiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="hero-slide-item hero-slide-1 bg-f position-relative">
                            <!-- <video autoplay muted loop id="myVideo">
                                <source src="./assets/video/slide-1.mp4" type="video/mp4">
                                Your browser does not support HTML5 video.
                              </video> -->
                            <div class="container">
                                <div class="row">
                                    <div class="col-xl-6 col-lg-8 col-md-10">
                                        <div class="hero-content">
                                            <span class="hero-subtitle d-inline-block fs-15 fw-semibold py-2" data-aos="fade-up" data-aos-once="true" data-aos-duration="500">Indias leading Digital Marketing Company</span>
                                            <h1 class="text-white hero-title" data-aos="fade-up" data-aos-once="true" data-aos-delay="500" data-aos-duration="500">Driving Growth,  <img src="./assets/images/onella/icons/up-right-arrow.svg" alt="Image"> Digitally</h1>
                                            <p class="hero-para" data-aos="fade-up" data-aos-once="true" data-aos-delay="1000" data-aos-duration="500">With Onella Soft Leading Your Business to Digital Excellence</p>
                                            <!-- <a href="#" class="btn style-one hero-btn">Schedule A Free Consultation</a> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container position-relative">
                    <div class="slider-pagination hero-pagination d-flex flex-column align-items-end"></div>
                </div>
            </div>
        </div>
        <!-- Hero Section End -->

        <!-- Feature Section Start-->
        <div class="container pt-130 pb-100">
            <div class="row justify-content-center">
                <div class="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-once="true" data-aos-duration="800">
                    <div class="feature-card style-one d-flex flex-wrap mb-30 transition">
                        <div class="feature-icon bg-optional position-relative d-flex flex-coulmn justify-content-center align-items-center transition">
                            <img src="./assets/images/onella/icons/calculation.svg" alt="Image" class="transition">
                        </div>
                        <div class="feature-text">
                            <h3 class="fs-20">Better ROI</h3> 
                            <p class="mb-0">By targeting the right customers and enhancing the value of your marketing efforts</p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-md-6 ps-xxl-4" data-aos="zoom-in" data-aos-once="true" data-aos-duration="800">
                    <div class="feature-card style-one d-flex flex-wrap mb-30 transition">
                        <div class="feature-icon bg-optional position-relative d-flex flex-coulmn justify-content-center align-items-center transition">
                            <img src="./assets/images/onella/icons/startup.svg" alt="Image" class="transition">
                        </div>
                        <div class="feature-text">
                            <h3 class="fs-20">Accelerate Market Entry</h3>
                            <p class="mb-0">By implementing small, modular experiments instead of large-scale campaigns, we achieve faster go-live times.</p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-md-6 ps-xxl-4" data-aos="zoom-in" data-aos-once="true" data-aos-duration="800">
                    <div class="feature-card style-one d-flex flex-wrap mb-30 transition">
                        <div class="feature-icon bg-optional position-relative d-flex flex-coulmn justify-content-center align-items-center transition">
                            <img src="./assets/images/onella/icons/data-management.svg" alt="Image" class="transition">
                        </div>
                        <div class="feature-text">
                            <h3 class="fs-20">Enhanced Consumer Focus</h3>
                            <p class="mb-0">Engaging users at each stage of the funnel, considering their journey and unique needs.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Feature Section End-->

        <!-- Moving Text Start -->
        <!-- <div class="move-text overflow-hidden">
            <ul class="list-unstyle">
                <li>Digital Marketing Company</li>
                <li>Software Development</li>
                <li>BITCOIN FARMING</li>
                <li>BUSINESS PLANNING</li>
                <li>BITCOIN FARMING</li>
            </ul>
        </div> -->
        <!-- Moving Text End -->

        <!-- Service Section Start -->
        <div class="service-wrap style-one position-relative index-1">
            <img src="./assets/images/onella/icons/footer-shape-1.webp" alt="Image" class="section-shape-one position-absolute">
            
            <div class="service-slider swiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="service-card-item position-relative index-1 ptb-130">
                            <div class="container">
                                <div class="section-title text-center mb-50">
                                    <span class="section-subtitle d-inline-block fs-15 fw-semibold text-title mb-40"
                                    data-aos="fade-up" data-aos-once="true" data-aos-duration="500">Our Services</span>
                                    <h2 class="mb-15" data-aos="fade-up" data-aos-once="true" data-aos-delay="500" data-aos-duration="500">Digital Agency</h2>
                                    <!-- <span class="service-counter mt-sm-20">01</span> -->
                                </div>
                                <div class="row align-items-center">
                                    <div class="col-lg-3">
                                        <div class="service-bg service-bg-1" data-aos="zoom-in" data-aos-once="true" data-aos-duration="500" data-aos-delay="1000"></div>
                                    </div>
                                    <div class="col-lg-9 ps-xxl-3">
                                        <div class="service-card-wrap d-flex flex-wrap">
                                            <div class="service-card bg-white text-center transition" 
                                            data-aos="flip-left" data-aos-once="true" data-aos-delay="1500" data-aos-duration="500">
                                                <img src="./assets/images/onella/icons/investment.svg" alt="Image" class="service-icon transition">
                                                <h3 class="fs-24"><a href="#" class="transition">Digital Marketing</a></h3>
                                                <p class="transition mb-0">Global reach, personalized efforts, real-time results, powerful tool</p>
                                                <!-- <a href="#" class="service-link d-flex flex-column align-items-center justify-content-center round-2 transition"><img src="./assets/images/onella/icons/right-arrow-2.svg" alt="Image"></a> -->
                                            </div>
                                            <div class="service-card bg-white text-center transition" 
                                            data-aos="flip-left" data-aos-once="true" data-aos-delay="2000" data-aos-duration="500">
                                                <img src="./assets/images/onella/icons/retirement.svg" alt="Image" class="service-icon transition">
                                                <h3 class="fs-24"><a href="#" class="transition">Software Development</a></h3>
                                                <p class="transition mb-0">Creating, maintaining websites with a focus on UI and UX</p>
                                                <!-- <a href="#" class="service-link d-flex flex-column align-items-center justify-content-center round-2 transition"><img src="./assets/images/onella/icons/right-arrow-2.svg" alt="Image"></a> -->
                                            </div>
                                            <div class="service-card bg-white text-center transition"
                                            data-aos="flip-left" data-aos-once="true" data-aos-delay="2500" data-aos-duration="500">
                                                <img src="./assets/images/onella/icons/education.svg" alt="Image" class="service-icon transition">
                                                <h3 class="fs-24"><a href="#" class="transition">Design Studio </a></h3>
                                                <p class="transition mb-0">Balance user-centric design for stunning solutions. Get expert UX audits</p>
                                                <!-- <a href="#" class="service-link d-flex flex-column align-items-center justify-content-center round-2 transition"><img src="./assets/images/onella/icons/right-arrow-2.svg" alt="Image"></a> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="slider-pagination service-pagination d-flex flex-lg-column align-items-center justify-content-center"></div>
            </div>
        </div>
        <!-- Service Section End -->

        <!-- About Section Start -->
        <div class="about-wrap style-one position-relative ptb-130 overflow-hidden">
            <img src="./assets/images/onella/icons/shape-1.webp" alt="Image" class="about-shape-one position-absolute md-none"
            data-aos="fade-down" data-aos-once="true" data-aos-duration="500">
            <!-- <img src="./assets/vendor/bruk/img/shape-3.webp" alt="Image" class="about-shape-two position-absolute md-none"> -->
            <div class="container">
                <div class="row align-items-xl-start align-items-center">
                    <div class="col-lg-6">
                        <div class="about-content">
                            <div class="section-title">
                                <span class="section-subtitle d-inline-block fs-15 fw-semibold text-title"
                                data-aos="fade-up" data-aos-once="true" data-aos-duration="500">About Us</span>
                                <h2 class="mb-15" data-aos="fade-up" data-aos-once="true" data-aos-duration="500" data-aos-delay="500">We Provide 360 Degree Solutions For business</h2>
                                <!-- <p>Onella Soft is the Best Website development and Digital Marketing Agency Located in Pune was established in the year 2024 by the professionals who have huge experience in the field of Website development, Digital Marketing & Performance Marketing.</p> -->
                                <p data-aos="fade-up" data-aos-once="true" data-aos-duration="500" data-aos-delay="1000">At Onella Soft , we offer 360 degree growth marketing solutions to help brands win long-term</p>
                            </div>
                            <div class="feature-list list-unstyle">
                                <div class="feature-item position-relative" data-aos="fade-up" data-aos-once="true" data-aos-duration="500" data-aos-delay="1500">
                                    <img src="./assets/images/onella/icons/check.svg" alt="Image" class="position-absolute start-0 top-0">
                                    <h5 class="fs-20 fw-semibold">Why Choose Us</h5>
                                    <p class="mb-0">What sets us apart is our clear dedication to delivering expertly crafted, technologically sound solutions that effectively address your business needs.</p>
                                </div>
                                <div class="feature-item position-relative" data-aos="fade-up" data-aos-once="true" data-aos-duration="500" data-aos-delay="2000">
                                    <img src="./assets/images/onella/icons/check.svg" alt="Image" class="position-absolute start-0 top-0">
                                    <h5 class="fs-20 fw-semibold">Who We Are</h5>
                                    <p class="mb-0">Onella Soft is an experienced and skilled group specializing in IT, Marketing, and Design. They operate based on the ethos of exploration, education, and advancement.</p>
                                </div>
                                <!-- <div class="feature-item position-relative">
                                    <img src="./assets/images/onella/icons/check.svg" alt="Image">
                                    <h5>High Quality Work Assurance</h5>
                                    <p class="mb-0">We giving high quality assurances of our work , an our team double check every work to prevent defects </p>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 ps-xxl-3">
                        <div class="about-img-wrap position-relative" data-aos="fade-up" data-aos-once="true" data-aos-duration="1000">
                            <img src="./assets/images/onella/icons/shape-2.webp" alt="Image" class="about-img-shape position-absolute">
                            <div class="about-img position-relative index-1"><img src="./assets/images/onella/services/services2.jpg" alt="Image"></div>
                            <!-- <img src="./assets/images/onella/about/crad.webp" alt="Image" class="credit-card position-absolute index-1 bounce"> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- About Section End -->

        <!-- Top banner start here-->
        <!-- <app-carousel-wrapper3 [carouselWrapper3Type]="'botStationAi'"></app-carousel-wrapper3> -->
        <!-- Top banner end here-->

        <!-- Working Process Section Start -->
        <div class="work-process-wrap pt-130 pb-100 position-relative" style="background-color: #eff4fe;">
            <!-- <img src="./assets/vendor/bruk/img/footer-shape-2.webp" alt="Image" class="section-shape position-absolute end-0 sm-none bounce"> -->
            <div class="container px-xxl-0">
                <div class="section-title text-center mb-50">
                    <span class="section-subtitle d-inline-block fs-15 fw-semibold text-title"
                    data-aos="fade-up" data-aos-once="true" data-aos-duration="500">Work Process</span>
                    <h2 class="mb-15"
                    data-aos="fade-up" data-aos-once="true" data-aos-duration="500" data-aos-delay="500">How It Works</h2>
                </div>
                <div class="work-card-wrap d-flex flex-wrap justify-content-center">
                    <div class="work-card text-center position-relative index-1 mb-30" 
                    data-aos="fade-up" data-aos-once="true" data-aos-duration="500" data-aos-delay="1000">
                        <h3 class="fs-24">Research & Strategy</h3>
                        <p>We employ a dual methodology in our research. Firstly, we assess our clients' performance relative to their competitors by analyzing consumer interactions. Next, we merge this data with the brand's initiatives to gauge their effectiveness. This approach enables us to deliver industry-leading digital marketing services.</p>
                        <span class="work-counter text-white fs-20 d-flex flex-column align-items-center justify-content-center mx-auto">01</span>
                    </div>
                    <div class="work-card text-center position-relative index-1 mb-30"
                    data-aos="fade-up" data-aos-once="true" data-aos-duration="500" data-aos-delay="1500">
                        <h3 class="fs-24">Idea Generation</h3>
                        <p>Idea generation in digital marketing involves the creative process of brainstorming and conceptualizing innovative strategies and content to engage target audiences effectively online</p>
                        <span class="work-counter text-white fs-20 d-flex flex-column align-items-center justify-content-center mx-auto">02</span>
                    </div>
                    <div class="work-card text-center position-relative index-1 mb-30"
                    data-aos="fade-up" data-aos-once="true" data-aos-duration="500" data-aos-delay="2000">
                        <h3 class="fs-24">Work Optimization</h3>
                        <p>As an advertising and digital marketing agency, Work Optimisation is at the heart of Onella Soft. To stay ahead of the game, we constantly strive to deliver Highly Optimised Work that is always available for our clients worldwide.</p>
                        <span class="work-counter text-white fs-20 d-flex flex-column align-items-center justify-content-center mx-auto">03</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- Working Process Section End -->

        <!-- Counter Section Start -->
        <!-- <div class="container position-relative">
            <img src="./assets/vendor/bruk/img/shape-5.webp" alt="Image" class="counter-shape-one position-absolute rotate">
            <div class="counter-card-wrap style-one d-flex flex-wrap pt-130 pb-100">
                <div class="counter-card position-relative text-center mb-30">
                    <h4>+<span class="counter">258</span>%</h4>
                    <p class="mb-0">Total increase in sales over only two months!</p>
                </div>
                <div class="counter-card position-relative text-center mb-30">
                    <h4>+<span class="counter">237</span>K</h4>
                    <p class="mb-0">A dedicated team of financial experts at your service.</p>
                </div>
                <div class="counter-card position-relative text-center mb-30">
                    <h4><span class="counter">8.2</span>X</h4>
                    <p class="mb-0">Rapid wealth financial for customer grow</p>
                </div>
                <div class="counter-card position-relative text-center mb-30">
                    <h4>$<span class="counter">10</span>+</h4>
                    <p class="mb-0">Cumulative trading volume for a customer sale</p>
                </div>
            </div>
        </div> -->
        <!-- Counter Section End -->


        <!-- Contact Section Start -->
        <!-- <div class="contact-wrap style-one position-relative ptb-130 overflow-hidden">
            <img src="./assets/vendor/bruk/img/shape-7.webp" alt="Image" class="contact-shape-one position-absolute start-0 bounce md-none">
            <img src="./assets/vendor/bruk/img/shape-5.webp" alt="Image" class="contact-shape-two position-absolute rotate md-none">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="contact-content">
                            <div class="section-title mb-30">
                                <span class="section-subtitle d-inline-block fs-15 fw-semibold text-title">Contact With Us</span>
                                <h2 class="mb-0">Contact With Us</h2>
                            </div>
                            <form action="#" class="contact-form">
                                <div class="form-group d-flex flex-wrap align-items-center justify-content-between">
                                    <label for="email">Your email</label>
                                    <input type="email" name="email" id="email" class="fs-15 h-52 bg-transparent">
                                </div>
                                <div class="form-group d-flex flex-wrap align-items-center justify-content-between">
                                    <label for="name">Your name</label>
                                    <input type="text" name="name" id="name" class="fs-15 h-52 bg-transparent">
                                </div>
                                <div class="form-group d-flex flex-wrap align-items-center justify-content-between">
                                    <label for="phone_no">Phone</label>
                                    <input type="number" name="phone_no" id="phone_no" class="fs-15 h-52 bg-transparent">
                                </div>
                                <div class="form-group d-flex flex-wrap align-items-center justify-content-between">
                                    <label for="website_url">Website</label>
                                    <input type="url" name="website_url" id="website_url" class="fs-15 h-52 bg-transparent">
                                </div>
                                <div class="form-group d-flex flex-wrap align-items-center justify-content-between">
                                    <label for="interest">Interested</label>
                                    <select name="interest" id="interest" class="fs-15 bg-trasnparent">
                                        <option value="1">Banking</option>
                                        <option value="2">Finance</option>
                                        <option value="3">Investment</option>
                                    </select>
                                </div>
                                <div class="form-group d-flex flex-wrap align-items-start justify-content-between">
                                    <label for="msg" class="pt-xxl-2">Message</label>
                                    <textarea name="msg" id="msg" cols="30" rows="10" class="bg-transparent resize-0" placeholder="Type a message"></textarea>
                                </div>
                                <div class="form-group d-flex flex-wrap align-items-center">
                                    <label for="msg"></label>
                                    <button class="btn style-two">Send Message</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="contact-img-wrap position-relative">
                            <div class="contact-img position-relative">
                                <img src="./assets/vendor/bruk/img/contact/contact-img-1.webp" alt="Image">
                            </div>
                            <div class="client-review position-absolute bg-optional d-flex align-items-center justify-content-between round-2">
                                <div class="trustlogo d-flex align-items-center">
                                    <div class="client-logo">
                                        <p class="fw-bold text-title">Review On</p>
                                        <img src="./assets/vendor/bruk/img/contact/trustpilot-logo.webp" alt="Image" class="logo-light">
                                        <img src="./assets/vendor/bruk/img/contact/trustpilot-logo-2.webp" alt="Image" class="logo-dark">
                                    </div>
                                    <div class="ratings">
                                        <ul class="list-unstyle d-flex">
                                            <li><img src="./assets/images/onella/icons/star-2.svg" alt="Image"></li>
                                            <li><img src="./assets/images/onella/icons/star-2.svg" alt="Image"></li>
                                            <li><img src="./assets/images/onella/icons/star-2.svg" alt="Image"></li>
                                            <li><img src="./assets/images/onella/icons/star-2.svg" alt="Image"></li>
                                            <li><img src="./assets/images/onella/icons/half-star.svg" alt="Image"></li>
                                        </ul>
                                        <span>544+ Reviews</span>
                                    </div>
                                </div>
                                <div class="client-ratings d-flex flex-wrap align-items-center justify-content-md-between">
                                    <img src="./assets/vendor/bruk/img/contact/google.webp" alt="Image" class="logo-light">
                                    <div class="ratings">
                                        <ul class="list-unstyle d-flex">
                                            <li><i class="ri-star-s-fill"></i></li>
                                            <li><i class="ri-star-s-fill"></i></li>
                                            <li><i class="ri-star-s-fill"></i></li>
                                            <li><i class="ri-star-s-fill"></i></li>
                                            <li><i class="ri-star-s-fill"></i></li>
                                        </ul>
                                        <span>Reviews 4.9/5.0</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- Contact Section End -->

        <!-- Team Section Start -->
        <!-- <div class="team-wrap style-one position-relative index-1 pb-130">
            <div class="team-bg team-bg-1 bg-f position-absolute top-0"></div>
            <div class="container">
                <div class="row align-items-end">
                    <div class="col-xxl-6 col-xl-7 col-lg-7 col-md-8 pe-xxl-2">
                        <div class="team-content bg-optional section-title">
                            <span class="section-subtitle d-inline-block fs-15 fw-semibold text-title">Our Team</span>
                            <h2 class="mb-15">Meet with Our Special Team Member</h2>
                            <p class="mb-0">We are dedicated to guiding you on your financial journey with expertise, integrity, and a personalized approach. Our team of experienced financial consultants is committed.</p>
                        </div>
                    </div>
                    <div class="col-xxl-6 col-xl-5 col-lg-5 col-md-4 text-md-end ps-xxl-5">
                        <a href="#" class="link style-one d-inline-block">Our Team Member <img src="./assets/images/onella/icons/long-arrow-right.svg" alt="Icons"></a>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- Team Section End -->


        <!-- Top banner start here-->
        <!-- <app-testimonial2 [testimonialWrapper2Type]="'botStationAi'"></app-testimonial2> -->
        <!-- Top banner end here-->


        <!-- Testimonial Section Start -->
        <!-- <div class="testimonial-wrap style-one bg-optional ptb-130">
            <div class="container position-relative">
                <img src="./assets/vendor/bruk/img/shape-3.webp" alt="Image" class="section-shape-one position-absolute rotate">
                <img src="./assets/vendor/bruk/img/shape-7.webp" alt="Image" class="section-shape-two position-absolute bounce">
                <div class="row">
                    <div class="col-xl-6 offset-xl-3 col-md-8 offset-md-2">
                        <div class="section-title text-center mb-50">
                            <span class="section-subtitle d-inline-block fs-15 fw-semibold text-title">Client Testimonials</span>
                            <h2 class="mb-0">What People Says About Our Financial Services</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="testimonial-slider-one swiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="testimonial-card style-one">
                            <div class="client-img-wrap d-flex justify-content-between">
                                <div class="client-img rounded-circle">
                                    <img src="assets/img/testimonials/client-1.webp" alt="Image" class="rounded-circle">
                                </div>
                                <img src="assets/img/testimonials/star.svg" alt="Image" class="star-icon">
                            </div>
                            <div class="client-quote bg-white ms-auto round-2">
                                <p>“Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore consectetur. Amet sed dolor for this net site.”</p>
                                <div class="client-info-wrap d-flex flex-wrap align-items-center justify-content-between">
                                    <div class="client-info">
                                        <h5 class="fs-20">Jenifer Brown</h5>
                                        <span>Product Designer</span>
                                    </div>
                                    <img src="./assets/images/onella/icons/square-quote.svg" alt="Image" class="quote-icon">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="testimonial-card style-one">
                            <div class="client-img-wrap d-flex justify-content-between">
                                <div class="client-img rounded-circle">
                                    <img src="assets/img/testimonials/client-2.webp" alt="Image" class="rounded-circle">
                                </div>
                                <img src="assets/img/testimonials/star.svg" alt="Image" class="star-icon">
                            </div>
                            <div class="client-quote bg-white ms-auto round-2">
                                <p>“Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore consectetur. Amet sed dolor for this net site.”</p>
                                <div class="client-info-wrap d-flex flex-wrap align-items-center justify-content-between">
                                    <div class="client-info">
                                        <h5 class="fs-20">Robert Taylor</h5>
                                        <span>Manager at UIC</span>
                                    </div>
                                    <img src="./assets/images/onella/icons/square-quote.svg" alt="Image" class="quote-icon">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="testimonial-card style-one">
                            <div class="client-img-wrap d-flex justify-content-between">
                                <div class="client-img rounded-circle">
                                    <img src="assets/img/testimonials/client-3.webp" alt="Image" class="rounded-circle">
                                </div>
                                <img src="assets/img/testimonials/star.svg" alt="Image" class="star-icon">
                            </div>
                            <div class="client-quote bg-white ms-auto round-2">
                                <p>“Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore consectetur. Amet sed dolor for this net site.”</p>
                                <div class="client-info-wrap d-flex flex-wrap align-items-center justify-content-between">
                                    <div class="client-info">
                                        <h5 class="fs-20">Jhon Smith</h5>
                                        <span>Web Developer</span>
                                    </div>
                                    <img src="./assets/images/onella/icons/square-quote.svg" alt="Image" class="quote-icon">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="testimonial-card style-one">
                            <div class="client-img-wrap d-flex justify-content-between">
                                <div class="client-img rounded-circle">
                                    <img src="assets/img/testimonials/client-4.webp" alt="Image" class="rounded-circle">
                                </div>
                                <img src="assets/img/testimonials/star.svg" alt="Image" class="star-icon">
                            </div>
                            <div class="client-quote bg-white ms-auto round-2">
                                <p>“Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore consectetur. Amet sed dolor for this net site.”</p>
                                <div class="client-info-wrap d-flex flex-wrap align-items-center justify-content-between">
                                    <div class="client-info">
                                        <h5 class="fs-20">Jenifer Brown</h5>
                                        <span>Product Designer</span>
                                    </div>
                                    <img src="./assets/images/onella/icons/square-quote.svg" alt="Image" class="quote-icon">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="testimonial-card style-one">
                            <div class="client-img-wrap d-flex justify-content-between">
                                <div class="client-img rounded-circle">
                                    <img src="assets/img/testimonials/client-1.webp" alt="Image" class="rounded-circle">
                                </div>
                                <img src="assets/img/testimonials/star.svg" alt="Image" class="star-icon">
                            </div>
                            <div class="client-quote bg-white ms-auto round-2">
                                <p>“Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore consectetur. Amet sed dolor for this net site.”</p>
                                <div class="client-info-wrap d-flex flex-wrap align-items-center justify-content-between">
                                    <div class="client-info">
                                        <h5 class="fs-20">Jenifer Brown</h5>
                                        <span>Product Designer</span>
                                    </div>
                                    <img src="./assets/images/onella/icons/square-quote.svg" alt="Image" class="quote-icon">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="slider-pagination testimonial-pagination d-flex align-items-center justify-content-center"></div>
            </div>
        </div> -->
        <!-- Testimonial Section End -->

        <!-- FAQ Section Start -->
        <!-- <div class="faq-wrap position-relative ptb-130 index-1">
            <img src="./assets/vendor/bruk/img/shape-11.webp" alt="Image" class="faq-shape-one position-absolute start-0 bounce">
            <img src="./assets/vendor/bruk/img/shape-12.webp" alt="Image" class="faq-shape-two position-absolute rotate md-none">
            <img src="./assets/vendor/bruk/img/shape-3.webp" alt="Image" class="faq-shape-three position-absolute moveHorizontal md-none">
            <div class="container">
                <div class="row align-items-xxl-start center align-items-center">
                    <div class="col-lg-6">
                        <div class="faq-img">
                            <img src="./assets/images/onella/about/faq-img-1.webp" alt="Image">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="faq-content">
                            <div class="section-title mb-25">
                                <span class="section-subtitle d-inline-block fs-15 fw-semibold text-title">FAQ's</span>
                                <h2 class="mb-15">People Asked To Bruk For Their Problem</h2>
                                <p>We are dedicated to guiding you on your financial journey with expertise, integrity, and a personalized approach. Our team of experienced.</p>
                            </div>
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item round-2">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button collapsed fs-20 text-title" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                            aria-expanded="false" aria-controls="collapseOne">
                                            <span class="d-flex flex-column align-items-center justify-content-center">
                                                <i class="ri-arrow-down-s-fill"></i>
                                            </span>
                                            How do I open an account?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>We are dedicated guiding you on your financial journey with, integrity, and a personalized approach. Our team of experienced.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item round-2">
                                    <h2 class="accordion-header" id="headingTwo">
                                        <button class="accordion-button collapsed fs-20 text-title" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                            aria-expanded="false" aria-controls="collapseTwo">
                                            <span class="d-flex flex-column align-items-center justify-content-center">
                                                <i class="ri-arrow-down-s-fill"></i>
                                            </span>
                                            How long does the your process usually take?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse"
                                        aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>We are dedicated guiding you on your financial journey with, integrity, and a personalized approach. Our team of experienced.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item round-2">
                                    <h2 class="accordion-header" id="headingThree">
                                        <button class="accordion-button collapsed fs-20 text-title" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                            aria-expanded="false" aria-controls="collapseThree">
                                            <span class="d-flex flex-column align-items-center justify-content-center">
                                                <i class="ri-arrow-down-s-fill"></i>
                                            </span>
                                            How involved will I be in the branding process?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse"
                                        aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>We are dedicated guiding you on your financial journey with, integrity, and a personalized approach. Our team of experienced.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item round-2">
                                    <h2 class="accordion-header" id="headingFour">
                                        <button class="accordion-button collapsed fs-20 text-title" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseFour"
                                            aria-expanded="false" aria-controls="collapseFour">
                                            <span class="d-flex flex-column align-items-center justify-content-center">
                                                <i class="ri-arrow-down-s-fill"></i>
                                            </span>
                                            How can I start financial project
                                        </button>
                                    </h2>
                                    <div id="collapseFour" class="accordion-collapse collapse"
                                        aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>We are dedicated guiding you on your financial journey with, integrity, and a personalized approach. Our team of experienced.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item round-2">
                                    <h2 class="accordion-header" id="headingFive">
                                        <button class="accordion-button fs-20 text-title" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseFive"
                                            aria-expanded="true" aria-controls="collapseFive">
                                            <span class="d-flex flex-column align-items-center justify-content-center">
                                                <i class="ri-arrow-down-s-fill"></i>
                                            </span>
                                            Is this template fully customizable?
                                        </button>
                                    </h2>
                                    <div id="collapseFive" class="accordion-collapse collapse show"
                                        aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>We are dedicated guiding you on your financial journey with, integrity, and a personalized approach. Our team of experienced.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- FAQ Section End -->

        <!-- Moving Text Start -->
        <!-- <div class="move-text overflow-hidden">
            <ul class="list-unstyle">
                <li>CONTACT WITH US</li>
                <li>CONTACT WITH US</li>
                <li>CONTACT WITH US</li>
                <li>CONTACT WITH US</li>
                <li>CONTACT WITH US</li>
            </ul>
        </div> -->
        <!-- Moving Text End -->
        <!-- Blog Section Start -->
        <!-- <div class="blog-wrap style-one position-relative index-1 ptb-130">
            <div class="container position-relative">
                <img src="./assets/vendor/bruk/img/shape-5.webp" alt="Image" class="blog-shape-one position-absolute rotate">
                <img src="./assets/vendor/bruk/img/shape-6.webp" alt="Image" class="blog-shape-two position-absolute bounce">
                <div class="row">
                    <div class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-8 offset-md-2">
                        <div class="section-title text-center mb-45">
                            <span class="section-subtitle d-inline-block fs-15 fw-semibold text-title">Blog And News</span>
                            <h2 class="mb-0">View Our Latest Blog And News From Bruk</h2>
                        </div>
                    </div>
                </div>
                <div class="blog-slider swiper">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <div class="blog-card style-one">
                                <div class="blog-info">
                                    <ul class="blog-metainfo list-unstyle">
                                        <li class="d-inline-block position-relative fs-15"><i class="ri-user-3-line"></i><a href="#">Admin</a></li>
                                        <li class="d-inline-block position-relative fs-15"><i class="ri-calendar-2-line"></i><a href="#">25 Jan, 2024</a></li>
                                    </ul>
                                    <h3><a href="#">Change your business life for your next project in 2024</a></h3>
                                    <p class="mb-0">Started with a diverse selection of courses, at  this resources, & tools tailored.</p>
                                </div>
                                <div class="blog-img position-relative round-2">
                                    <img src="./assets/vendor/bruk/img/blog/blog-1.webp" alt="Image" class="transition round-2">
                                    <a href="#" class="blog-link d-flex flex-column align-items-center justify-content-center round-2 bg-white position-absolute start-50 transition"><img src="./assets/images/onella/icons/long-arrow-blue.svg" alt="Image"></a>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="blog-card style-one">
                                <div class="blog-info">
                                    <ul class="blog-metainfo list-unstyle">
                                        <li class="d-inline-block position-relative fs-15"><i class="ri-user-3-line"></i><a href="#">Admin</a></li>
                                        <li class="d-inline-block position-relative fs-15"><i class="ri-calendar-2-line"></i><a href="#">22 Jan, 2024</a></li>
                                    </ul>
                                    <h3><a href="#">Create your digital branding with expert team member</a></h3>
                                    <p class="mb-0">Started with a diverse selection of courses, at  this resources, & tools tailored.</p>
                                </div>
                                <div class="blog-img position-relative round-2">
                                    <img src="./assets/vendor/bruk/img/blog-2.webp" alt="Image" class="transition round-2">
                                    <a href="#" class="blog-link d-flex flex-column align-items-center justify-content-center round-2 bg-white position-absolute start-50 transition"><img src="./assets/images/onella/icons/long-arrow-blue.svg" alt="Image"></a>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="blog-card style-one">
                                <div class="blog-info">
                                    <ul class="blog-metainfo list-unstyle">
                                        <li class="d-inline-block position-relative fs-15"><i class="ri-user-3-line"></i><a href="#">Admin</a></li>
                                        <li class="d-inline-block position-relative fs-15"><i class="ri-calendar-2-line"></i><a href="#">21 Jan, 2024</a></li>
                                    </ul>
                                    <h3><a href="#">Business life is not changing for a local services</a></h3>
                                    <p class="mb-0">Started with a diverse selection of courses, at  this resources, & tools tailored.</p>
                                </div>
                                <div class="blog-img position-relative round-2">
                                    <img src="./assets/vendor/bruk/img/blog-3.webp" alt="Image" class="transition round-2">
                                    <a href="#" class="blog-link d-flex flex-column align-items-center justify-content-center round-2 bg-white position-absolute start-50 transition"><img src="./assets/images/onella/icons/long-arrow-blue.svg" alt="Image"></a>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="blog-card style-one">
                                <div class="blog-info">
                                    <ul class="blog-metainfo list-unstyle">
                                        <li class="d-inline-block position-relative fs-15"><i class="ri-user-3-line"></i><a href="#">Admin</a></li>
                                        <li class="d-inline-block position-relative fs-15"><i class="ri-calendar-2-line"></i><a href="#">18 Jan, 2024</a></li>
                                    </ul>
                                    <h3><a href="#">This trend report of future? An AI interprets fall 2024</a></h3>
                                    <p class="mb-0">Started with a diverse selection of courses, at  this resources, & tools tailored.</p>
                                </div>
                                <div class="blog-img position-relative round-2">
                                    <img src="assets/img/blog/blog-4.webp" alt="Image" class="transition round-2">
                                    <a href="#" class="blog-link d-flex flex-column align-items-center justify-content-center round-2 bg-white position-absolute start-50 transition"><img src="./assets/images/onella/icons/long-arrow-blue.svg" alt="Image"></a>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="blog-card style-one">
                                <div class="blog-info">
                                    <ul class="blog-metainfo list-unstyle">
                                        <li class="d-inline-block position-relative fs-15"><i class="ri-user-3-line"></i><a href="#">Admin</a></li>
                                        <li class="d-inline-block position-relative fs-15"><i class="ri-calendar-2-line"></i><a href="#">16 Jan, 2024</a></li>
                                    </ul>
                                    <h3><a href="#">Gigapresses the giant die cast reshape car industry</a></h3>
                                    <p class="mb-0">Started with a diverse selection of courses, at  this resources, & tools tailored.</p>
                                </div>
                                <div class="blog-img position-relative round-2">
                                    <img src="assets/img/blog/blog-5.webp" alt="Image" class="transition round-2">
                                    <a href="#" class="blog-link d-flex flex-column align-items-center justify-content-center round-2 bg-white position-absolute start-50 transition"><img src="./assets/images/onella/icons/long-arrow-blue.svg" alt="Image"></a>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="blog-card style-one">
                                <div class="blog-info">
                                    <ul class="blog-metainfo list-unstyle">
                                        <li class="d-inline-block position-relative fs-15"><i class="ri-user-3-line"></i><a href="#">Admin</a></li>
                                        <li class="d-inline-block position-relative fs-15"><i class="ri-calendar-2-line"></i><a href="#">12 Jan, 2024</a></li>
                                    </ul>
                                    <h3><a href="#">Audio sunglasses that are easy to fall in 2024</a></h3>
                                    <p class="mb-0">Started with a diverse selection of courses, at  this resources, & tools tailored.</p>
                                </div>
                                <div class="blog-img position-relative round-2">
                                    <img src="assets/img/blog/blog-6.webp" alt="Image" class="transition round-2">
                                    <a href="#" class="blog-link d-flex flex-column align-items-center justify-content-center round-2 bg-white position-absolute start-50 transition"><img src="./assets/images/onella/icons/long-arrow-blue.svg" alt="Image"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slider-pagination blog-pagination d-flex flex-wrap align-items-center justify-content-center"></div>
                </div>
            </div>
        </div> -->
        <!-- Blog Section End -->

        <!-- Subscribe Section Start -->
        <!-- <app-subscribe1 [subscribeWrapper1Type]="'botStationAi'"></app-subscribe1> -->
        <!-- Subscribe Section End -->

        <!-- Footer Start -->
        <app-footer4></app-footer4>
        <!-- <footer class="footer-wrap position-relative pt-130">
            <img src="./assets/images/onella/icons/footer-shape-1.webp" alt="Image" class="footer-shape-one position-absolute sm-none">
            <img src="./assets/vendor/bruk/img/footer-shape-2.webp" alt="Image" class="footer-shape-two position-absolute end-0 sm-none">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-xl-4">
                        <h3 class="fs-24 mb-0">Subscribe To Our Newsletter</h3>
                    </div>
                    <div class="col-xl-8 ps-xxl-0">
                        <div class="subscribe-form d-flex align-items-center">
                            <p class="mb-0 fw-semibold">Enter your email</p>
                            <form action="#" class="d-flex flex-grow-1 align-items-center">
                                <input type="email" class="bg-transparent h-52">
                                <button class="btn">Subscribe Now</button>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="footer-widget-wrap d-flex flex-wrap pb-100">
                    <div class="footer-widget mb-25">
                        <a href="#" class="logo">
                            <img src="assets/img/logo.webp" alt="Logo" class="logo-light">
                            <img src="assets/img/logo-white.webp" alt="Logo" class="logo-dark">
                        </a>
                        <p class="comp-desc">With a rich history spanning decades, our club has become a symbol of excellence in the golfing community. offers a challenging yet rewarding game play.</p>
                        <ul class="social-profile list-unstyle">
                            <li><a href="https://www.facebook.com/" target="_blank"><i class="ri-facebook-fill"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="ri-instagram-line"></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class="ri-twitter-x-line"></i></a></li>
                            <li><a href="https://web.whatsapp.com/"><i class="ri-whatsapp-line"></i></a></li>
                        </ul>
                    </div>
                    <div class="footer-widget mb-25">
                        <h3 class="footer-widget-title fs-20 fw-medium">Quick Links</h3>
                        <ul class="footer-menu list-unstyle">
                            <li><a href="#">About Us</a></li>
                            <li><a href="#">Contact Us</a></li>
                            <li><a href="#">Our Members</a></li>
                            <li><a href="#">Services</a></li>
                            <li><a href="#">FAQ’s</a></li>
                        </ul>
                    </div>
                    <div class="footer-widget mb-25">
                        <h3 class="footer-widget-title fs-20 fw-medium">Services</h3>
                        <ul class="footer-menu list-unstyle">
                            <li><a href="#">Retirement</a></li>
                            <li><a href="#">Investment</a></li>
                            <li><a href="#">Education</a></li>
                            <li><a href="#">Management</a></li>
                            <li><a href="#">Tax planning</a></li>
                        </ul>
                    </div>
                    <div class="footer-widget mb-25">
                        <h3 class="footer-widget-title fs-20 fw-medium">Company</h3>
                        <ul class="footer-menu list-unstyle">
                            <li><a href="#">Terms & Conditions</a></li>
                            <li><a href="#">Our Projects</a></li>
                            <li><a href="#">Pricing Plan</a></li>
                            <li><a href="#">Privacy Policy</a></li>
                            <li><a href="#">Member Single</a></li>
                        </ul>
                    </div>
                    <div class="footer-widget mb-25">
                        <h3 class="footer-widget-title fs-20 fw-medium">Our Contact</h3>
                        <ul class="contact-list list-unstyle">
                            <li class="position-relative"><i class="ri-message-2-line"></i><a href="https://templates.hibotheme.com/cdn-cgi/l/email-protection#157d7079797a557767607e3b767a78"><span class="__cf_email__" data-cfemail="147c7178787b547666617f3a777b79">[email&#160;protected]</span></a></li>
                            <li class="position-relative"><i class="ri-phone-line"></i><a href="tel:487432602396">+48 74326 02396</a></li>
                            <li class="position-relative"><i class="ri-phone-line"></i><a href="tel:486445396533">+48 64453 96533</a></li>
                            <li class="position-relative"><i class="ri-calendar-line"></i>Mon - Fri 12:00 - 18:00</li>
                            <li class="position-relative"><i class="ri-map-pin-line"></i>TY56/90 NY, Hilton, USA</li>
                        </ul>
                    </div>
                </div>
            </div>
            <p class="copyright-text text-center text-offwhite mb-0"><i class="ri-copyright-line"></i><span class="text-white fw-semibold">Bruk</span> is proudly owned by <a href="https://hibotheme.com/" class="link style-one" target="_blank">HiboTheme</a></p>
        </footer> -->
        <!-- Footer End -->



<!-- Footer1 section start here -->
<!-- <app-footer1 [footer1Type]="'training'"></app-footer1> -->
<!-- Footer1 section end here -->

<!-- Go-top1 section start here -->
<app-go-top1></app-go-top1>
<!-- Go-top1 section end here -->